import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import BrandContext from '../../context/BrandContext';
import brandDetailsOnStageInitialState from '../../context/brandDetailsOnStageInitialState';
import BrandRankingsItem from './BrandRankingsItem';
import VirtualizedBrandRankingsListItemContainer from './VirtualizedBrandRankingsListItemContainer';
import brandRankingsListPropTypes from '../../../prop-types/brandRankingsListPropTypes';
import appConfig from '../../../data/appConfig.json';
import brandUtils from '../../../utils/brandUtils';

const VirtualizedBrandRankingsListItem = ({ data, index, style }) => {
    const { countries } = appConfig;
    const { cardHeight, cardWidth, columnCount, itemCount, brandRankingsList } = data;
    const startIndex = index * columnCount;
    const stopIndex = Math.min(itemCount - 1, startIndex + columnCount - 1);
    const cards = [];

    const {
        setBrandDetailsOnStage,
        setBrandProductsOnStage,
        setProductListCurrentRowIndex,
    } = useContext(BrandContext);

    for (let i = startIndex; i <= stopIndex; i += 1) {
        cards.push(
            <Fragment key={i}>
                <Helmet>
                    <link
                        rel="prefetch"
                        href={`/api/brands/brand-${brandUtils.getBrandSlug(
                            brandRankingsList[i].title,
                        )}-country-${countries[0]}.json`}
                        as="fetch"
                    />
                </Helmet>
                <BrandRankingsItem
                    title={brandRankingsList[i].title}
                    to={brandRankingsList[i].to}
                    onClick={() => {
                        setBrandDetailsOnStage({ ...brandDetailsOnStageInitialState });
                        setBrandProductsOnStage(undefined);
                        setProductListCurrentRowIndex(undefined);
                    }}
                    totalCount={brandRankingsList[i].totalCount}
                    cardWidth={cardWidth}
                    cardHeight={cardHeight}
                    isLast={itemCount - 1 === i}
                />
            </Fragment>,
        );
    }
    return (
        <VirtualizedBrandRankingsListItemContainer style={style}>
            {cards}
        </VirtualizedBrandRankingsListItemContainer>
    );
};

VirtualizedBrandRankingsListItem.propTypes = {
    data: PropTypes.shape({
        cardHeight: PropTypes.number,
        cardWidth: PropTypes.number,
        columnCount: PropTypes.number,
        gapSize: PropTypes.number,
        itemCount: PropTypes.number,
        brandRankingsList: brandRankingsListPropTypes.brandRankingsList,
    }).isRequired,
    index: PropTypes.number.isRequired,
    style: PropTypes.shape({
        height: PropTypes.number,
        left: PropTypes.number,
        position: PropTypes.string,
        right: PropTypes.number,
        top: PropTypes.number,
        width: PropTypes.string,
    }).isRequired,
};

export default VirtualizedBrandRankingsListItem;
