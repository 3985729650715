import styled from 'styled-components';
import { rem } from 'polished';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const BrandRankingsContainer = styled.div`
    padding: ${({ theme }) => rem(theme.site.stage.paddingTop)} 0 0;

    ${media.md`
        padding: ${({ theme }) => rem(theme.site.stage.paddingTop)} 0;
    `}
`;

export default BrandRankingsContainer;
