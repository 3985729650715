import styled, { css } from 'styled-components';
import { rem } from 'polished';

const BrandRankingsItemContainer = styled.div`
    border-bottom: ${({ theme }) => rem(theme.site.brandRankings.card.borderWidth)} solid
        ${({ theme }) => theme.base.grays.grayLighter};
    padding: ${rem(20)} 0;
    width: 90%;

    ${({ isLast }) =>
        isLast &&
        css`
            &:last-of-type {
                border-bottom: 0;
            }
        `}
`;

export default BrandRankingsItemContainer;
