import PropTypes from 'prop-types';

const brandRankingsListItem = PropTypes.shape({
    title: PropTypes.string.isRequired,
    retailersAmount: PropTypes.number.isRequired,
    to: PropTypes.string.isRequired,
    availableCountries: PropTypes.arrayOf(PropTypes.string),
});

const brandRankingsList = PropTypes.arrayOf(brandRankingsListItem);

export default { brandRankingsListItem, brandRankingsList };
