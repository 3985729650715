import { rem } from 'polished';
import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';
import { cssHelpers } from '@luigiclaudio/ga-baseline-ui/helpers';

const BrandRankingsIntro = styled.div`
    ${cssHelpers.clearfix}

    ${media.md`
        margin: ${rem(16)} 0;
    `}
`;

export default BrandRankingsIntro;
