import styled from 'styled-components';
import scrollbarStyles from '../../../styles/scrollbarStyles';

const BrandRankingsListContainer = styled.div`
    overflow-y: scroll;
    position: relative;

    ${scrollbarStyles}
`;

export default BrandRankingsListContainer;
