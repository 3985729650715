import { rem } from 'polished';
import styled from 'styled-components';
import { media } from '@luigiclaudio/ga-baseline-ui/layout';

const BrandRankingsHeader = styled.div`
    box-shadow: 0 16px 16px -18px rgba(0, 0, 0, 0.5);
    margin: 0 -${rem(16)};
    padding: 0 ${rem(16)} ${rem(16)};
    position: relative;
    z-index: 1;

    ${media.md`
        box-shadow: none;
    `}
`;

export default BrandRankingsHeader;
