import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { GatsbyA } from '@luigiclaudio/ga-baseline-ui/typography';
import BrandTitle from '../../BrandTitle';
import BrandRankingsItemContainer from './BrandRankingsItemContainer';
import BrandRankingsItemAmountsContainer from './BrandRankingsItemAmountsContainer';
import BrandRankingsItemAmountsLabel from './BrandRankingsItemAmountsLabel';
import BrandRankingsItemTitleContainer from './BrandRankingsItemTitleContainer';
import BrandRankingsItemTitle from './BrandRankingsItemTitle';
import appConfig from '../../../../data/appConfig.json';

const BrandRankingsItem = ({ intl, title, totalCount, to, onClick, cardWidth, isLast }) => {
    const { countries } = appConfig;

    const toLink = `/${to}-${countries[0]}`;

    return (
        <BrandRankingsItemContainer isLast={isLast}>
            <BrandRankingsItemTitle>
                <BrandRankingsItemTitleContainer cardWidth={cardWidth}>
                    <GatsbyA color="dark" to={toLink} onClick={onClick}>
                        <BrandTitle>{title}</BrandTitle>
                    </GatsbyA>
                </BrandRankingsItemTitleContainer>
            </BrandRankingsItemTitle>
            <BrandRankingsItemAmountsContainer>
                <BrandRankingsItemAmountsLabel>
                    <FormattedMessage
                        id="brandCard.itemsAmount"
                        values={{ amount: intl.formatNumber(totalCount) }}
                    />
                </BrandRankingsItemAmountsLabel>
            </BrandRankingsItemAmountsContainer>
        </BrandRankingsItemContainer>
    );
};

BrandRankingsItem.propTypes = {
    intl: PropTypes.shape().isRequired,
    title: PropTypes.string.isRequired,
    totalCount: PropTypes.number.isRequired,
    to: PropTypes.string.isRequired,
    cardWidth: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    isLast: PropTypes.bool,
};

BrandRankingsItem.defaultProps = {
    onClick: () => {},
    isLast: false,
};

export default injectIntl(BrandRankingsItem);
