import React, { useMemo, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ThemeContext } from 'styled-components';
import { FixedSizeList } from 'react-window';
import VirtualizedBrandRankingsListItem from './VirtualizedBrandRankingsListItem';
import BrandRankingsListContainer from './BrandRankingsListContainer';
import brandRankingsListPropTypes from '../../../prop-types/brandRankingsListPropTypes';

const VirtualizedBrandRankingsList = ({ itemWidth, itemHeight, itemCount, brandRankingsList }) => {
    const list = useRef();
    const { values } = useFormikContext();

    useEffect(() => {
        if (list) {
            list.current.scrollToItem(0);
        }
    }, [values]);

    const itemData = useMemo(
        () => ({
            columnCount: 1,
            itemCount,
            cardWidth: itemWidth,
            cardHeight: itemHeight,
        }),
        [itemCount],
    );

    const theme = useContext(ThemeContext);
    const cardHeight = theme.site.brandRankings.card.height;
    const cardPaddingV = theme.site.brandRankings.card.paddingV * 2;
    const cardBorderWidth = theme.site.brandRankings.card.borderWidth * 2;

    const itemSize = cardHeight + cardPaddingV + cardBorderWidth;

    return (
        <FixedSizeList
            ref={list}
            height={itemHeight}
            width={itemWidth}
            itemCount={itemCount}
            itemSize={itemSize}
            itemData={{ ...itemData, brandRankingsList }}
            outerElementType={BrandRankingsListContainer}
        >
            {VirtualizedBrandRankingsListItem}
        </FixedSizeList>
    );
};

VirtualizedBrandRankingsList.propTypes = {
    itemHeight: PropTypes.number.isRequired,
    itemCount: PropTypes.number.isRequired,
    itemWidth: PropTypes.number.isRequired,
    brandRankingsList: brandRankingsListPropTypes.brandRankingsList.isRequired,
};

VirtualizedBrandRankingsList.defaultProps = {};

export default VirtualizedBrandRankingsList;
