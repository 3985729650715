import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import AutoSizer from 'react-virtualized-auto-sizer';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ThemeContext } from 'styled-components';
import { Col, Row } from '@luigiclaudio/ga-baseline-ui/layout';
import { H1, P } from '@luigiclaudio/ga-baseline-ui/typography';
import { FormButtonGroup, FormRadioButton } from '@luigiclaudio/ga-baseline-ui/form';
import { GaIcon } from '@luigiclaudio/ga-baseline-ui/icon';
import VirtualizedBrandRankingsList from './VirtualizedBrandRankingsList';
import BrandRankingsSortFiltersContainer from './BrandRankingsSortFiltersContainer';
import BrandRankingsListFadeContainer from './BrandRankingsListFadeContainer';
import BrandRankingsSortFilterContainer from './BrandRankingsSortFilterContainer';
import BrandRankingsSortFilterLabel from './BrandRankingsSortFilterLabel';
import BrandRankingsHeader from './BrandRankingsHeader';
import BrandRankingsIntro from './BrandRankingsIntro';
import useAllBrand from '../../../services/useAllBrand';
import useAppUser from '../../../services/useAppUser';
import appConfig from '../../../data/appConfig.json';
import brandUtils from '../../../utils/brandUtils';

const BrandRankingsContent = ({ intl }) => {
    const brandList = useAllBrand();
    const theme = useContext(ThemeContext);
    const { values } = useFormikContext();
    const { sortByItems } = values;

    const brandRankingsHeaderBind = useRef();

    const { userActiveGroups, userActiveCountries } = useAppUser();

    const { groups, countries } = appConfig;

    const [brandListByUser, setBrandListByUser] = useState([]);
    const [currentBrandListByUser, setCurrentBrandListByUser] = useState();
    const [brandRankingsHeaderHeight, setBrandRankingsHeaderHeight] = useState();

    useEffect(() => {
        setBrandListByUser(
            userActiveGroups && userActiveCountries
                ? brandUtils.brandListByUserPreferences({
                      brandList,
                      activeGroups: userActiveGroups,
                      activeCountries: userActiveCountries,
                  })
                : brandUtils.brandListByUserPreferences({
                      brandList,
                      activeGroups: groups,
                      activeCountries: countries,
                  }),
        );
    }, [userActiveGroups, userActiveCountries]);

    useEffect(() => {
        if (brandListByUser) {
            if (sortByItems === 'highToLow') {
                setCurrentBrandListByUser(
                    brandUtils.sortBrandsByProductsAmountHightToLow(brandListByUser),
                );
            }
            if (sortByItems === 'lowToHigh') {
                setCurrentBrandListByUser(
                    brandUtils.sortBrandsByProductsAmountLowToHigh(brandListByUser),
                );
            }
        }
    }, [brandListByUser, sortByItems]);

    const checkBrandRankingsHeaderHeight = () => {
        setBrandRankingsHeaderHeight(
            brandRankingsHeaderBind.current.getBoundingClientRect().height,
        );
    };

    useEffect(() => {
        setBrandRankingsHeaderHeight(
            brandRankingsHeaderBind.current?.getBoundingClientRect().height,
        );
    }, [brandRankingsHeaderBind.current]);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', checkBrandRankingsHeaderHeight);
            return () => window.removeEventListener('resize', checkBrandRankingsHeaderHeight);
        }
        return null;
    }, []);

    const sortHighToLowIcon = theme.site.sort.icon.highToLow;
    const sortLowToHighIcon = theme.site.sort.icon.lowToHigh;
    const sortIconSize = theme.site.sort.iconSize;

    return (
        <Row justifyContent="center">
            <Col xxs={30} lg={12}>
                <Row justifyContent="center">
                    <Col xxs={30} lg={22}>
                        <BrandRankingsHeader ref={brandRankingsHeaderBind}>
                            <BrandRankingsIntro>
                                <H1>
                                    <FormattedMessage
                                        id="brandRankings.title"
                                        values={{
                                            amount: intl.formatNumber(brandListByUser.length),
                                        }}
                                    />
                                </H1>
                                <P>
                                    <FormattedMessage id="brandRankings.subtitle" />
                                </P>
                            </BrandRankingsIntro>
                            <BrandRankingsSortFiltersContainer>
                                <BrandRankingsSortFilterContainer>
                                    <BrandRankingsSortFilterLabel colorValue="muted">
                                        <FormattedMessage id="brandRankings.sortByItems.label" />
                                    </BrandRankingsSortFilterLabel>
                                    <Field
                                        component={FormButtonGroup}
                                        id="sortByItems"
                                        name="sortByItems"
                                        buttonSkin="muted"
                                        buttonSize="sm"
                                        role="radiogroup"
                                        title={intl.formatMessage({
                                            id: 'brandRankings.sortByItems.label',
                                        })}
                                    >
                                        <Field
                                            component={FormRadioButton}
                                            id="sortByItems"
                                            name="sortByItems"
                                            value="highToLow"
                                            aria-label={intl.formatMessage({
                                                id:
                                                    'brandRankings.sortByItems.highToLow.button.label',
                                            })}
                                        >
                                            <GaIcon icon={sortHighToLowIcon} size={sortIconSize} />
                                        </Field>
                                        <Field
                                            component={FormRadioButton}
                                            id="sortByItems"
                                            name="sortByItems"
                                            value="lowToHigh"
                                            aria-label={intl.formatMessage({
                                                id:
                                                    'brandRankings.sortByItems.lowToHigh.button.label',
                                            })}
                                        >
                                            <GaIcon icon={sortLowToHighIcon} size={sortIconSize} />
                                        </Field>
                                    </Field>
                                </BrandRankingsSortFilterContainer>
                            </BrandRankingsSortFiltersContainer>
                        </BrandRankingsHeader>
                    </Col>
                </Row>
            </Col>
            <Col xxs={30} lg={16}>
                <Row justifyContent="center">
                    <Col xxs={30} lg={22}>
                        <BrandRankingsListFadeContainer headerOffset={brandRankingsHeaderHeight}>
                            <AutoSizer>
                                {({ height, width }) => {
                                    return (
                                        <VirtualizedBrandRankingsList
                                            itemWidth={width}
                                            itemHeight={height}
                                            itemCount={currentBrandListByUser.length}
                                            brandRankingsList={currentBrandListByUser}
                                        />
                                    );
                                }}
                            </AutoSizer>
                        </BrandRankingsListFadeContainer>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

BrandRankingsContent.propTypes = {
    intl: PropTypes.shape().isRequired,
};

export default injectIntl(BrandRankingsContent);
